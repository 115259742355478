import React, { useContext, useEffect } from 'react';
import { SchedularContext } from './calendarStore';
import { filteredEmployees as filteredEmployeesLogic, makeKeyYearDates } from '../calendarFunctions';
import OneEmployee from './oneEmployee';

const EmployeesList = () => {
  const [
    {
      loading,
      departments,
      filters,
      allShifts,
      activeYear,
      activeWeek,
      allUsers,
      activeCalendarDates
    },
  ] = useContext(SchedularContext);

  if (loading || !allUsers) {
    return <></>;
  }

  const { start: startDate, end: endDate } = activeCalendarDates;
  const dateRangeCode = makeKeyYearDates(startDate, endDate);
  const employees = allUsers[dateRangeCode];

  const filteredEmployees = filteredEmployeesLogic(
    employees,
    departments,
    filters,
    allShifts,
    activeYear,
    activeWeek
  );

  if (!filteredEmployees.length > 0) {
    return <></>;
  }

  return (
    <div className="employees-list">
      {filteredEmployees.map((emp) => (
        <OneEmployee key={emp.id} employee={emp} departments={departments} />
      ))}
    </div>
  );
};

export default EmployeesList;
