import {DialogController} from 'aurelia-dialog';
import {inject} from "aurelia-framework";
import {FormField} from "../../../form/object/form-field";

@inject(
    DialogController
)
export class SelectMediaBundleView {
    constructor(
        dialogController
    ) {
        this.dialogController = dialogController;
    }

    async activate(context)
    {
        const dummyFormService = {
            change: () => {}
        };

        this.field = new FormField(
            dummyFormService,
            {
                'type': 'choice',
                'modelId': 'media-database/media-bundle',
                'multiple': false,
                'required': true
            },
            'mediaBundle',
            null
        );

        this.title   = context.title;
        this.onSave  = context.onSave;
        this.loading = false;
    }

    async ok()
    {
        this.loading = true;

        return this.onSave(this.field.getValue()).then(
            (response) => {
                // Don't set loading to false, because dialog will be closed anyway.
                // If you set loading = false here, user will see blink of dialog controls.
                //this.loading = false;

                return Promise.resolve(this.dialogController.ok())
            }
        ).catch(
            (error) => {
                this.loading = false;

                throw error;
            }
        );
    }
}
