import {inject, LiteralString, bindingMode} from 'aurelia-framework';
import {ChoiceLoader} from './loader/choice-loader';
import * as _ from 'lodash';
import {I18N} from 'aurelia-i18n';

function loadChoices(newValue) {

    this.choiceLoader.getChoices(this.choiceConfig).then((data) => {

        let returnValue = '';
        let property = this.choiceProperty ?? 'label';

        _.each(data, (element) => {

            //So that integer values are also displayed correctly
            if (element.value == newValue) {
                returnValue = element[property] ?? '';
            }
        });

        //Already unbound in the meantime
        if (!this.choiceMethod || !this.i18n) {
            return;
        }

        let translate = true;

        if (this.choiceConfig.set || this.choiceConfig.modelId || this.choiceConfig.modelPropertyId) {
            translate = false;
        }

        this.choiceMethod(translate ? this.i18n.tr(returnValue) : returnValue);
    });
}
/**
 * Finds label for choice label
 *
 * Supports only choice sets atm, as for model id it could be that value is not in result
 */
@inject(ChoiceLoader, I18N)
export class ChoiceBindingBehavior {

    //Taken from aurelia throttle binding behavior

    constructor(choiceLoader, i18n) {
        this.choiceLoader = choiceLoader;
        this.i18n = i18n;
    }

    bind(binding, source, config, returnProperty) {
        // determine which method to throttle.
        let methodToThrottle = 'updateTarget'; // one-way bindings or interpolation bindings
        if (binding.callSource) {
            methodToThrottle = 'callSource';     // listener and call bindings
        } else if (binding.updateSource && binding.mode === bindingMode.twoWay) {
            methodToThrottle = 'updateSource';   // two-way bindings
        }

        // stash the original method and it's name.
        // note: a generic name like "originalMethod" is not used to avoid collisions
        // with other binding behavior types.
        binding.choiceMethod = binding[methodToThrottle];
        binding.choiceMethod.originalName = methodToThrottle;

        binding.choiceLoader = this.choiceLoader;
        binding.i18n = this.i18n;

        if (_.isString(config)) {
            config = {set: config};
        }

        binding.choiceConfig = config;
        binding.choiceProperty = returnProperty;

        // replace the original method with the throttling version.
        binding[methodToThrottle] = loadChoices;
    }

    unbind(binding, source) {
        // restore the state of the binding.
        let methodToRestore = binding.choiceMethod.originalName;
        binding[methodToRestore] = binding.choiceMethod;

        binding.choiceMethod = null;
        binding.choiceLoader = null;
        binding.choiceConfig = null;
        binding.choiceProperty = null;
        binding.i18n = null;
    }
}
