import {LogManager} from 'aurelia-framework';

const logger = LogManager.getLogger('AuthTokenStorage');
logger.setLevel(LogManager.logLevel.none); // Disable logging for this class by setting "LogManager.logLevel.none"

export class AuthTokenStorage {
    _accessToken;
    _refreshToken;
    _expiresAt;

    isLoggedIn()
    {
        return !!this.getAccessToken();
    }

    isTokenExpired()
    {
        const now = new Date();
        //Adding some buffer here so tokens are renewed early
        now.setMinutes(now.getMinutes() + 30);
        const expiresAt = this.getExpiresAt();

        if (!expiresAt) {
            return true;
        }

        return now > expiresAt;
    }

    getExpiresAt()
    {
        //Always load token from storage, because if session gets renewed, token gets changed in storage
        //(and other tabs also need new token)
        const expiresAtStr = this._getTokenFromLocalStorage('access_token_expires_at');

        logger.debug('get access_token_expires_at', this._expiresAt, expiresAtStr);

        if (expiresAtStr) {
            this._expiresAt = new Date(expiresAtStr);
        }

        return this._expiresAt;
    }

    getAccessToken()
    {
        //Always load token from storage, because if session gets renewed, token gets changed in storage
        //(and other tabs also need new token)
        let token = this._getTokenFromLocalStorage('access_token');

        logger.debug('get access_token', this._accessToken, token);

        if (!token) {
            return this._accessToken;
        }

        return token;
    }

    getRefreshToken()
    {
        //Always load token from storage, because if session gets renewed, token gets changed in storage
        //(and other tabs also need new token)
        let token =  this._getTokenFromLocalStorage('refresh_token');

        logger.debug('get refresh_token', this._refreshToken, token);

        if (!token) {
            return this._refreshToken;
        }

        return token;
    }

    setExpiresAt(expiresAt)
    {
        this._expiresAt = expiresAt;

        this._setTokenInLocalStorage('access_token_expires_at', expiresAt ? expiresAt.toISOString() : null);
    }

    setAccessToken(token)
    {
        this._accessToken = token;

        this._setTokenInLocalStorage('access_token', token);
    }

    setRefreshToken(token)
    {
        this._refreshToken = token;

        this._setTokenInLocalStorage('refresh_token', token);
    }

    _getTokenFromLocalStorage(storageKey)
    {
        return localStorage.getItem(storageKey) || '';
    }

    _setTokenInLocalStorage(storageKey, token)
    {
        if (token) {
            localStorage.setItem(storageKey, token);
        } else {
            localStorage.removeItem(storageKey);
        }
    }
}
