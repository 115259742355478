import {customElement, bindable} from "aurelia-framework";
import {AbstractCell} from './abstract-cell';

@customElement('sio-cell-price-charge')
export class PriceCharge extends AbstractCell
{
    @bindable column;
    @bindable item;

    itemChanged()
    {
        this.bind();
    }
}
