import {customElement, bindable, inject} from 'aurelia-framework';
import {Client} from '../api/client';
import {MongoDataLoader} from "../table/loader/mongo-data-loader";
import {FlashService} from "../flash/flash-service";
import {I18N} from "aurelia-i18n";
import * as _ from 'lodash';
import moment from 'moment';

@customElement('sio-timelog-calendar')
@inject(Client, FlashService, I18N)
export class TimeLogCalendar
{
    @bindable user;

    constructor(client)
    {
        this.client = client;
        this.callback = this.loadEvents.bind(this);
    }

    loadEvents(config, startDate, endDate)
    {
        let conditions = {
            startDate: startDate.format('YYYY-MM-DD'),
            endDate: endDate.format('YYYY-MM-DD')
        };

        if (this.user) {
            conditions.users = [this.user];
        }

        let query = $.param(conditions);

        return this.client.get('human-resources/time-log-events?' + query).then(results => {

            let modified = [];

            _.each(results, result => {

                modified.push({
                    start: result.startDate,
                    end: result.endDate,
                    allDay: result.allDay,
                    title: result.title,
                    color: result.color
                })
            });

            return modified;
        });
    }
}