import {inject, LogManager} from "aurelia-framework";
import {I18N} from "aurelia-i18n";
import {ConditionMatcher} from "../condition-builder/condition-matcher.js";
import {BulkOperationsRepository} from "./bulk-operations-repository.js";

const logger = LogManager.getLogger("ActionsGenerator");

@inject(
    I18N,
    ConditionMatcher,
    BulkOperationsRepository
)
export class ActionsGenerator
{
    /*public*/ constructor(
        i18n,
        conditionMatcher,
        bulkOperationsRepository
    ) {
        this.i18n = i18n;
        this.conditionMatcher = conditionMatcher;
        this.bulkOperationsRepository = bulkOperationsRepository;
    }

    /*public*/ generateActions(items, operationConfig)
    {
        logger.debug("Regenerate bulk actions", items, operationConfig, this);

        if (!this.matchConditions(items, operationConfig)) {
            return [];
        }

        const operation = this.bulkOperationsRepository.getOperation(operationConfig.type);

        return operation.getActions(items, operationConfig);
    }

    /*private*/ matchConditions(items, operationConfig)
    {
        let matchesAll = true;

        if (operationConfig.conditions) {
            // Match conditions on each selected item
            if (operationConfig.conditions.item) {
                items.forEach(item => {
                    const matchesOne = this.conditionMatcher.matchConditions(
                        item, operationConfig.conditions.item
                    );

                    if (!matchesOne) {
                        matchesAll = false;
                        return false;
                    }
                });
            }

            // in future here can be conditions to match on bulk itself (not on each item),
            // like minimum count of items, or some other complicated logic.
        }

        return matchesAll;
    }
}
