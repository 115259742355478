import {DialogController} from 'aurelia-dialog';
import {inject} from "aurelia-framework";
import {UserClient} from "../../../api/user-client";

@inject(
    DialogController,
    UserClient
)
export class OneFieldView {
    constructor(
        dialogController,
        userClient
    ) {
        this.dialogController = dialogController;
        this.userClient = userClient;
    }

    async activate(context)
    {
        let user = this.userClient.getUser();

        this.dialogController.settings.overlayDismiss = user?.settings?.userSettings?.closeDialogsWhenClickingOutside ?? false;

        this.configuration = {
            fields: [
                context.field.formField
            ]
        };

        this.property = context.field.formField.property;
        this.title   = context.title;
        this.onSave  = context.onSave;
        this.loading = false;
    }

    async submit(object)
    {
        this.loading = true;

        return this.onSave(object[this.property]).then(
            (response) => {
                // Don't set loading to false, because dialog will be closed anyway.
                // If you set loading = false here, user will see blink of dialog controls.
                //this.loading = false;

                return Promise.resolve(this.dialogController.ok())
            }
        ).catch(
            (error) => {
                this.loading = false;

                throw error;
            }
        );
    }
}
