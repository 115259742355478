export class OperationInterface {
    getName()
    {
        throw new Error('OperationInterface::getName() must be implemented in subclass.');
    }

    execute(context)
    {
        throw new Error('OperationInterface::execute() must be implemented in subclass.');
    }

    getActions(items, operationConfig)
    {
        throw new Error('OperationInterface::getActions() must be implemented in subclass.');
    }
}
