import {DialogService} from 'aurelia-dialog';
import {inject} from "aurelia-framework";
import {FlashService} from "../../flash/flash-service";
import {Client} from "../../api/client";
import {GenerateDialog} from "../generate/generate-dialog";
import i18next from "i18next";

@inject(
    DialogService,
    FlashService,
    Client
)
export class ArtificialIntelligence {
    constructor(
        dialogService,
        flashService,
        client
    ) {
        this.dialogService = dialogService;
        this.flashService = flashService;
        this.client = client;
    }

    validateResponseObj(res) {
        // when dialog closed, do nothing
        if (!res) {
            return true;
        }

        if (!res.response) {
            const error = i18next.t('artificial-intelligence.api.request.error.no-response');

            console.dir(error);
            this.flashService.error(error);

            return true;
        }

        return false;
    }

    async generate(ctx) {
        try {
            const dialogResponse = await this.dialogService.open({
                viewModel: GenerateDialog,
                model: {formContext: ctx}
            }).whenClosed();

            if (!dialogResponse?.output) {
                return;
            }

            const res = dialogResponse.output[0]?.detail?.response?.data;

            if (!res) {
                const err = i18next.t('artificial-intelligence.api.request.error.no-response');

                console.error(err);
                this.flashService.error(err);

                return;
            }

            const historyPendingEntryId = res.id;

            const {data} = await this.client.patch('artificial-intelligence/request/' + historyPendingEntryId);

            if (data && data.response) {
                this.flashService.success('artificial-intelligence.form.generation-request.status.success');
                return data;
            } else {
                this.flashService.error('artificial-intelligence.form.generation-request.status.error');
            }

        } catch (error) {
            this.flashService.error(error?.localizedMessage ?? error?.message ?? error?.data?.localizedMessage ??
                error?.data?.error ?? error?.data?.message ?? error ?? 'Error');
            console.error('Error:', error);
        }
    }
}
