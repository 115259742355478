import {inject} from "aurelia-framework";
import {OperationInterface} from "./operation.interface";
import {ConditionMatcher} from "../../condition-builder/condition-matcher.js";
import * as _ from "lodash";

@inject(
    ConditionMatcher
)
export class TableRowActionsOperation extends OperationInterface
{
    constructor(
        conditionMatcher
    ) {
        super();

        this.conditionMatcher = conditionMatcher;
    }

    /*public*/ getName()
    {
        return 'table-row-actions';
    }

    /*public*/ execute(context)
    {
        throw new Error('Must be never called.');
    }

    /*public*/ getActions(items, operationConfig)
    {
        const actions = [];

        operationConfig.actions.forEach(
            action => {
                if (false === action.bulk) {
                    return;
                }

                let matched = true;

                if (action.conditions) {
                    _.forEach(items, item => {
                        matched &= this.conditionMatcher.matchConditions(item, action.conditions)
                    });
                }

                if (matched) {
                    actions.push(action);
                }
            }
        );

        return actions;
    }
}
