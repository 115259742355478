import { customElement, bindable, inject, noView } from "aurelia-framework";
import { render, unmountComponentAtNode } from "react-dom";
import React from "react";
import CalendarDisplay from "./calendar-display";
import { I18N } from "aurelia-i18n";

@noView
@inject(Element, I18N)
@customElement("calendar-display-aurelia")
export default class CalendarDisplayAurelia {
    @bindable data;
    @bindable createFormId;
    @bindable referenceModelId;
    @bindable detailPageId;

    localeMap = {
        de: 'de-de',
        en: 'en-us',
    };

    constructor(element, i18n) {
        this.element = element;
        this.i18n = i18n;
    }

    attached() {
        this.renderCalendar();
    }

    detached() {
        unmountComponentAtNode(this.element);
    }

    dataChanged() {
        this.renderCalendar();
    }

    async renderCalendar() {
        const locale = this.i18n.getLocale();
        const localeString = this.localeMap[locale] || 'en-us';
        render(
            <CalendarDisplay locale={localeString} createFormId={this.createFormId}  referenceModelId={this.referenceModelId} detailPageId={this.detailPageId} {...this.data}   />,
            this.element
        );
    }
}
