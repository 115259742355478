import { customElement, inject } from 'aurelia-framework';
import Client from '../../api/client';
import './email-with-content.less';
import { Cell } from '../cell';
@customElement('sio-cell-email-with-content')
@inject(Client, Element, Cell)
export class EmailWithContent {
    constructor(client, element, cell) {
        this.client = client;
        this.element = element;
        this.item = cell.getItem();
        this.column = cell.getColumn();
        this.loading = false;
        this.show = false;
        this.body = '';
    }

    async fetchEmailContent() {
        this.show = true;
        this.updatePopoverBoundings();
        if (!this.body) {
            this.loading = true;

            if (this.item.htmlMessage) {
                this.body = this.item.htmlMessage;
            } else {
                try {
                    const response = await this.client
                        .getClient()
                        .fetch(this.item.bodyUrl, {method: 'GET'});
                    this.body = await response.text();
                } catch (error) {
                    console.error('Error fetching email content:', error);
                }
            }

            this.loading = false;
        }
    }

    updatePopoverBoundings() {
        const hoverableRect = this.element
            .querySelector('.hoverable')
            .getBoundingClientRect();
        const popover = this.element.querySelector('.show-on-hover');
        popover.style.top = hoverableRect.bottom + 'px';
        popover.style.left = hoverableRect.left + 'px';
    }

    hideEmailContent() {
        this.show = false;
    }
}
