import {Aurelia, inject, LogManager} from 'aurelia-framework';
import {AuthTokenStorage} from './auth-token-storage';
import {AuthService} from './auth-service';

const logger = LogManager.getLogger('auth');

@inject(
    Aurelia,
    AuthTokenStorage,
    AuthService
)
export class AuthorizeStep
{
    constructor(
        aurelia,
        authTokenStorage,
        authService
    ) {
        this.aurelia = aurelia;
        this.authTokenStorage = authTokenStorage;
        this.authService = authService;
    }

    async run(routingContext, next)
    {
        await this.authService.refreshSession();

        if (this.authTokenStorage.isLoggedIn() || routingContext.config.settings.noAuth) {
            return next();
        }

        logger.debug('Redirecting to login, because accessing route without login and auth is required', routingContext);

        this.aurelia.setRoot('login/login');
        return next.cancel();
    }

}
