import dayjs from 'dayjs';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import duration from 'dayjs/plugin/duration';
import isoWeek from 'dayjs/plugin/isoWeek';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import 'dayjs/locale/de';
import { updateStore } from './components/calendarStore';
import {
    defaultDuration,
    showSelectedEmployeeInFilterList,
} from './components/config';

dayjs.locale('de');
dayjs.extend(weekOfYear);
dayjs.extend(duration);
dayjs.extend(isoWeek);
dayjs.extend(utc);
dayjs.extend(timezone);

// api endpoints

export const addUserInShift = async (emp, shift, client, tasks = []) => {
    const postData = { user: emp, shift: shift };
    if (tasks.length > 0) {
        postData.tasks = tasks;
    }
    try {
        const data = await client.post('shift/add-shift-user', postData);

        return data;
    } catch (e) {
        return e;
    }
};

export const editShift = async (shift, client) => {
    const data = await client.post('shift/shift-edit/' + shift, {
        employee: [],
    });
    return data;
};
export const getShifts = async (client, fromDate, toDate, accommodation) => {
    let url = 'shift/get-shifts?';
    if (fromDate && toDate) {
        toDate = dayjs(toDate).add(1, 'day').format('YYYY-MM-DD');
        url =
            url +
            `conditions={"shiftStartDate": {"$gte": "${fromDate}","$lt": "${toDate}"}}&`;
    }
    if (accommodation) {
        url = url + `accommodationId=${accommodation}`;
    }
    const data = await client.get(url);
    return { shifts: data?.shifts || [], settings: data?.settings };
};

export const getWeeklyShifts = async (setStoreData, client, accommodation) => {
    const { start, end } = getDefaultDates(defaultDuration);
    setStoreData((s) => updateStore(s, 'loading', true));
    const shiftsData = await getShiftsIfNotThere(
        start,
        end,
        {},
        client,
        setStoreData,
        false,
        accommodation
    );
    const currentYear = dayjs().year();
    const currentWeekNumber = makeKeyYearDates(start, end);
    setStoreData((s) => updateStore(s, 'activeYear', currentYear));
    setStoreData((s) => updateStore(s, 'activeWeek', currentWeekNumber));
};
export const getCurrentWeekEmployees = async (
    setStoreData,
    client,
    accommodation = ''
) => {
    const { start, end } = currentWeekDates('YYYY-MM-DD');
    getEmployees(setStoreData, client, start, end, accommodation);
};

export const getMonthFromDate = (date)=>{
    return dayjs(date).format("MMMM")
}
export const getEmployees = async (
    setStoreData,
    client,
    fromDate,
    toDate,
    accommodation = ''
) => {
    const conditions = {
        archived: { $ne: true },
        $or: [
            {
                excludeFromShiftPlanning: {
                    $exists: false,
                },
            },
            { excludeFromShiftPlanning: false },
        ],
    };

    if (accommodation) {
        conditions['companyAllocation'] = { $in: [accommodation] };
    }

    const params = {
        conditions: JSON.stringify({
            $and: [
                {
                    $and: [conditions],
                },
            ],
        }),
    };

    const searchParams = new URLSearchParams(params);

    if (fromDate && toDate) {
        searchParams.append('startDate', fromDate);
        searchParams.append('endDate', toDate);
    }

    let url = `shift/get-users`;
    const data = await client.get(`${url}?${searchParams.toString()}`);

    const dateRangeCode = makeKeyYearDates(fromDate, toDate);
    setStoreData((s) =>
        updateStore(s, 'allUsers.' + dateRangeCode, data?.users)
    );
};

export const getDepartments = async (setStoreData, client) => {
    const data = await client.get('shift/get-department');
    setStoreData((s) => updateStore(s, 'departments', data));
};

export const getAccommodations = async (setStoreData, client) => {
    const data = await client.get('shift/get-accommodations');
    setStoreData((s) => updateStore(s, 'accommodations', data?.accommodations));
    setStoreData((s) => updateStore(s, 'loading', false));
};

export const removeEmployeeFromStore = (
    setStoreData,
    activeYear,
    activeWeek,
    emp,
    shift
) => {
    setStoreData((s) => {
        return {
            ...s,
            allShifts: {
                ...s.allShifts,
                [activeYear]: {
                    ...s.allShifts[activeYear],
                    [activeWeek]: s.allShifts[activeYear][activeWeek].map(
                        (s) => {
                            if (s.id == shift) {
                                return {
                                    ...s,
                                    employee: s.employee.filter(
                                        (e) => e.id != emp
                                    ),
                                };
                            } else {
                                return s;
                            }
                        }
                    ),
                },
            },
        };
    });
};
export const removeEmployeeFromShift = async (
    emp,
    shift,
    setStoreData,
    activeYear,
    activeWeek,
    client,
    allShifts
) => {
    removeEmployeeFromStore(setStoreData, activeYear, activeWeek, emp, shift);

    const newData = await client.post('shift/remove-shift-user', {
        user: emp,
        shift: shift,
    });

    if (newData?.employee?.includes(emp)) {
        updateShiftWithEmployee(
            emp,
            shift,
            setStoreData,
            activeYear,
            activeWeek
        );
    }
};

//  dayjs functions

export const datesDuration = (startDate, endDate, type, prec = false) => {
    const start = dayjs(startDate);
    const end = dayjs(endDate);

    return Math.abs(end.diff(start, type, prec));
};
export const getWeekNumberFromDate = (date) => {
    return dayjs(date).week();
};

export const getWeekDates = (year, weekNumber, format = 'YYYY-MM-DD') => {
    const startDate = dayjs().year(year).week(weekNumber).startOf('week');
    const endDate = dayjs().year(year).week(weekNumber).endOf('week');
    return {
        start: startDate.format(format),
        end: endDate.format(format),
    };
};
export const getDefaultDates = (duration) => {
    return {
        start: dayjs().startOf('week').format('YYYY-MM-DD'),
        end: dayjs()
            .startOf('week')
            .add(duration - 1, 'day')
            .format('YYYY-MM-DD'),
    };
};
export const currentWeekDates = (format = 'YYYY-MM-DD') => {
    return getWeekDates(dayjs().year(), dayjs().week(), format);
};
export const getStartOfWeekFromDate = (date) => {
    return dayjs(date).startOf('isoWeek').format('YYYY-MM-DD');
};

export const weeksMath = (date, logic, duration = 7, format = 'YYYY-MM-DD') => {
    if (logic == 'add') {
        const startDate = dayjs(date);
        return startDate.add(duration, 'day').format(format);
    }

    if (logic == 'minus') {
        const startDate = dayjs(date);
        return startDate.subtract(duration, 'day').format(format);
    }
};

export const formatDate = (date, format = 'YYYY-MM-DD') => {
    return dayjs(date).format(format);
};

export const getYearWeekFromDate = (rawDate) => {
    const date = dayjs(rawDate);
    const year = date.isoWeekYear();
    const week = date.isoWeek();
    return `${year}-W${String(week).padStart(2, '0')}`;
};
// component Logic

export const getEmployeeName = (empId, employees) => {
    if (employees?.length > 0) {
        const emp = employees.find((e) => e?.id === empId);
        return emp?.username;
    } else {
        return '';
    }
};
export const getMemberDepartments = (memberId, allDepartments) => {
    return allDepartments?.filter((department) =>
        department?.members?.some((member) => member?.id === memberId)
    );
};

export const filteredEmployees = (
    allEmp,
    allDepartments,
    filters,
    allShifts,
    activeYear,
    activeWeek
) => {
    const { departments, employees } = filters || {};
    let filteredEmployees = allEmp;

    // dont include employees which are already selected in current week
    const currentWeekShifts =
        allShifts && allShifts[activeYear] && allShifts[activeYear][activeWeek];

    if (currentWeekShifts?.length > 0 && !showSelectedEmployeeInFilterList) {
        const selectedEmployees = currentWeekShifts.flatMap((s) => {
            return s.employee.map((s) => s.id);
        });
        if (selectedEmployees?.length > 0) {
            filteredEmployees = filteredEmployees.filter(
                (e) => !selectedEmployees.includes(e?.id)
            );
        }
    }

    if (employees?.length > 0) {
        filteredEmployees = filteredEmployees.filter((emp) =>
            employees.includes(emp?.id)
        );
    }

    if (departments?.length > 0) {
        let allMembers = departments.flatMap((d) => {
            const relDepartment = allDepartments.find((de) => de.id === d);
            return relDepartment.members?.map((m) => m?.id);
        });
        filteredEmployees = filteredEmployees.filter((emp) =>
            allMembers.includes(emp?.id)
        );
    }

    return filteredEmployees;
};

export const makeShiftEvents = (shifts) => {
    const shiftEvents = shifts.map((shift) => {
        return {
            start: dayjs(shift.shiftStartDate)
                .local()
                .format('YYYY-MM-DDTHH:mm:ss'),
            end: dayjs(shift.shiftEndDate)
                .local()
                .format('YYYY-MM-DDTHH:mm:ss'),
            text: shift.title,
            id: shift.id,
            items: shift.employee,
        };
    });
    return shiftEvents;
};

export const getCurrentWeekShifts = async (client) => {
    const { start, end } = currentWeekDates('YYYY-MM-DD');
    const shiftsData = await getShifts(client, start, end);
    return shiftsData;
};

export const removeEmployeeFromList = (empId, setStore) => {
    if (!showSelectedEmployeeInFilterList) {
        setStore((s) => {
            return {
                ...s,
                employees: s.employees.filter((e) => e?.id !== empId),
            };
        });
    }
};

export const updateShiftWithEmployee = (
    emp,
    shiftId,
    setStore,
    activeYear,
    activeWeek
) => {
    setStore((s) => {
        return {
            ...s,
            allShifts: {
                ...s.allShifts,
                [activeYear]: {
                    ...s.allShifts[activeYear],
                    [activeWeek]: s.allShifts[activeYear][activeWeek].map(
                        (s) => {
                            if (
                                s.id == shiftId &&
                                !s.employee.find((e) => e.id == emp.id)
                            ) {
                                return { ...s, employee: [...s.employee, emp] };
                            } else {
                                return s;
                            }
                        }
                    ),
                },
            },
        };
    });
};

export const makeVisibleShifts = (filters, accommodation, shifts) => {
    const departments = filters?.departments;
    const accommodations = filters?.accommodations;
    let filteredShifts = shifts;

    if (departments?.length > 0) {
        filteredShifts = filteredShifts.filter((shift) =>
            departments.includes(shift.department.id)
        );
    }

    if (accommodations?.length > 0) {
        filteredShifts = filteredShifts.filter((shift) =>
            accommodations.includes(shift?.accommodation?.id)
        );
    }

    if (accommodation) {
        filteredShifts = filteredShifts.filter(
            (shift) => shift?.accommodation?.id === accommodation
        );
    }

    return filteredShifts;
};
export const makeKeyYearDates = (start, end) => {
    return (
        dayjs(start).format('DDMMYYYY') + '_' + dayjs(end).format('DDMMYYYY')
    );
};
export const refreshEmployeeList = async (
    sDate,
    eDate,
    allShifts,
    allUsers,
    client,
    setStoreData,
    isRefresh
) => {
    const dateRangeCode = makeKeyYearDates(sDate, eDate);

    if (!allUsers[dateRangeCode] || isRefresh) {
        setStoreData((s) => updateStore(s, 'shiftsLoading', true));
        await getEmployees(setStoreData, client, sDate, eDate);
        setStoreData((s) => updateStore(s, 'shiftsLoading', false));
    }
};

export const getShiftsIfNotThere = async (
    sDate,
    eDate,
    allShifts,
    client,
    setStoreData,
    isRefresh = false,
    accommodation
) => {
    const weekNumber = makeKeyYearDates(sDate, eDate);
    const yearNumber = dayjs(sDate).year();
    if (
        !allShifts[yearNumber] ||
        !allShifts[yearNumber][weekNumber] ||
        isRefresh
    ) {
        setStoreData((s) => updateStore(s, 'shiftsLoading', true));
        const { shifts, settings } = await getShifts(
            client,
            sDate,
            eDate,
            accommodation
        );
        shiftUpdater(shifts, yearNumber, weekNumber, setStoreData);
        setStoreData((s) => ({
            ...s,
            activeWeek: weekNumber,
            activeYear: yearNumber,
            calendarSettings: settings,
        }));
        setStoreData((s) => updateStore(s, 'shiftsLoading', false));
    } else {
        setStoreData((s) => ({
            ...s,
            activeWeek: weekNumber,
            activeYear: yearNumber,
        }));
    }
};

export const shiftUpdater = (shifts, year, week, setStoreData) => {
    setStoreData((s) => ({
        ...s,
        allShifts: {
            ...s.allShifts,
            [year]: {
                ...(s.allShifts && s.allShifts[year]),
                [week]: shifts,
            },
        },
    }));
};
