import {InlineViewStrategy, noView} from 'aurelia-framework';
import * as _ from 'lodash';
import getPropertyByPath from "../../utilities/get-property-by-path";

@noView
export class Custom
{
    column;
    item;

    activate(model) {
        if (!model) return;

        this.column = model.column;
        this.item = model.item;

        this.options = this.column.options;
        this.value = this.getValue();
    }

    getValue() {
        if (this.column.property == null) {
            return this.item;
        }

        // noinspection JSUnresolvedVariable
        return getPropertyByPath(this.item, this.column.property)
    }

    getViewStrategy() {
        return new InlineViewStrategy('<template>' + this.column.template + '</template>');
    }
}

