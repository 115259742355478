import api, {onQueryStarted} from "../../store/api"

const bankingApi = api.injectEndpoints({
    endpoints: build => ({
        assign: build.mutation({
            query: ({id, body}) => ({
                url: `banking-api/assign/trigger/${id}`,
                method: "PATCH",
                body
            }),
            onQueryStarted: onQueryStarted(),
            invalidatesTags: (result, error, {id}) => [{type: "banking-api/transaction", id}],
        }),
    })
})

export const {
    useAssignMutation
} = bankingApi
