import { bindable, customElement, inject } from "aurelia-framework";
import { Client } from "../../../api/client";
import * as _ from 'lodash';

@customElement('sio-availability-scheduler')
@inject(Client)
export class AvailabilityScheduler {
    @bindable formId;
    @bindable postUrl;
    @bindable createFormId;
    @bindable referenceModelId;
    @bindable detailPageId;
    offset = 0;
    _isSubmitting = false;
    data = null;

    constructor(client) {
        this.client = client;
        this.submit = this.submit.bind(this);
    }

    submit(offsetChange = 0) {
        const data = this.form.formService.getValue();
        this.data = null;

        if (offsetChange !== 0 && offsetChange !== true) {
            this.offset += offsetChange;
        } else if (offsetChange === true) {
            this.offset = 1;
        } else {
            this.offset = 0;
        }

        data.currentPage = this.offset;
        this._isSubmitting = true;

        return this.client.post(this.postUrl, data)
            .then(response => {
                this.updateData(response.data);
                this._isSubmitting = false;
                return response;
            })
            .catch(error => {
                this._isSubmitting = false;
                throw error;
            });
    }

    clearForm() {
        if (this.form && this.form.formService) {
            _.forEach(this.form.formService.config.fields, (field) => {
                field.resetValue();
            });
        }

        this.data = null;
        this.offset = 0;
    }

    updateData(data) {
        this.data = data;
        this.resources = data.resources;
        this.pageSize = data.pageSize;
        this.showAllResources = data.showAllResources;
        this.hasEvent = data.hasEvent;
        this.totalPages = Math.ceil(data.restCount / this.pageSize);
    }

    isNextDisabled() {
        return (
            this.showAllResources ||
            this._isSubmitting ||
            this.offset >= this.totalPages ||
            this.resources.length === 0
        );
    }
}
