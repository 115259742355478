import {customElement, bindable} from "aurelia-framework";
import {formatCurrency} from "../../../toma-mask/fast-booking/components/functions/fucntions";

@customElement('sio-money-display')
export class Money
{
    @bindable value;
    formatCurrencies() {
        let contentText = '';
        for (const price of this.value.currencies) {
            contentText += formatCurrency(price) + ' <br>';
        }
        return contentText;
    }

}
