import React, { createContext, useState, useEffect } from 'react';

export const SchedularContext = createContext();

//  context values
// {
// loading
// allShifts {year: {weekNumber: [shiftsList]}
// allUsers {dateRange: [userList ]}
// activeCalendarDates {start ,  end }
// departments
// duration
// filters: {departments, employees}
// activeYear
// activeWeek
// shiftsLoading
// calendarSettings
// accommodation
// }
const SchedularProvider = ({ children, value }) => {
  const [contextProviderValue, setContextProviderValue] = useState(value);
//   useEffect(() => {
//     console.log(contextProviderValue, 'contextProviderValue');
//   }, [contextProviderValue]);

  return (
    <SchedularContext.Provider
      value={[contextProviderValue, setContextProviderValue]}
    >
      {children}
    </SchedularContext.Provider>
  );
};

export default SchedularProvider;

export const updateStore = (store, key, value) => {
  const keys = key.split('.');
  const updatedStore = { ...store };
  let current = updatedStore;

  for (let i = 0; i < keys.length - 1; i++) {
    const k = keys[i];
    if (!(k in current)) {
      current[k] = {};
    }
    current = current[k];
  }
  current[keys[keys.length - 1]] = value;

  return updatedStore;
};
