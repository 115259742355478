import {inject} from "aurelia-framework";
import {OperationInterface} from "./operation.interface";
import {DialogService} from "aurelia-dialog";
import {BulkOperationQueryCore} from "./utility/bulk-operation-query-core";
import {I18N} from "aurelia-i18n";
import {AiImageTaggingView} from "./utility/ai-image-tagging-view";
import {UserClient} from "../../api/user-client";

@inject(
    UserClient,
    DialogService,
    BulkOperationQueryCore,
    I18N
)
export class AiImageTagging extends OperationInterface {
    constructor(
        userClient,
        dialogService,
        bulkOperationQueryCore,
        i18n
    ) {
        super();

        this.userClient = userClient;
        this.dialogService = dialogService;
        this.bulkOperationQueryCore = bulkOperationQueryCore;
        this.i18n = i18n;
    }

    /*public*/
    getName() {
        return 'ai-image-tagging';
    }

    /*public*/
    execute(context) {
        return this.dialogService.open({
            viewModel: AiImageTaggingView,
            model: {
                title: 'artificial-intelligence.integration.media-database.image-tagging.bulk-operation.label',
                onSave: this.onSaveCallback(context).bind(this)
            }
        }).whenClosed();
    }


    /*public*/
    getActions(items, operationConfig) {
        const isArtificialIntelligenceEnabled = (this.userClient?.getUser()?.
            instance?.settings?.artificialIntelligenceInstanceSettings?.isActive) ?? false;

        if (true !== isArtificialIntelligenceEnabled) {
            return [];
        }

        return [
            {
                type: 'bulk-operation',
                operationConfig: operationConfig,
                label: this.i18n.tr('artificial-intelligence.integration.media-database.image-tagging.bulk-operation.label'),
            }
        ];
    }

    /*private*/
    onSaveCallback(context) {
        return (artificialIntelligenceTagsValueSettingType) => {
            return this.bulkOperationQueryCore.query(
                context.operationConfig.type,
                {
                    items: context.items,
                    artificialIntelligenceTagsValueSettingType: artificialIntelligenceTagsValueSettingType
                }
            )
        };
    }
}
