import {customElement, bindable} from "aurelia-framework";
import {AbstractCell} from './abstract-cell';

@customElement('sio-cell-reference-display')
export class ReferenceDisplay extends AbstractCell
{
    @bindable column;
    @bindable item;

    _defaultOptions = {
        multiModel: false,
        separator: ', '
    };

    itemChanged()
    {
        this.bind();
    }
}