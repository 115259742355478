import {customElement, bindable} from "aurelia-framework";
import {AbstractCell} from './abstract-cell';
import * as _ from "lodash";

@customElement('sio-cell-phone')
export class Phone extends AbstractCell {
    @bindable column;
    @bindable item;

    itemChanged() {
        this.bind();
    }

    getValue() {
        const value = super.getValue();

        if (!value) {
            return value;
        }

        return this.column.many ? value : [value];
    }
}
