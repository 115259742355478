import {customElement, bindable} from "aurelia-framework";
import {AbstractCell} from './abstract-cell';
import * as _ from "lodash";

@customElement('sio-cell-file')
export class File extends AbstractCell
{
    @bindable column;
    @bindable item;

    itemChanged()
    {
        this.bind();
    }

    getValue()
    {
        let value = super.getValue();

        if (!_.isArray(value)) {
            value = [value];
        }

        return value;
    }
}
