import {inject} from "aurelia-framework";
import {OperationInterface} from "./operation.interface";
import {OneFieldOperationUtilities} from "./utility/one-field-operation-utilities"
import * as _ from "lodash";
import {I18N} from "aurelia-i18n";

@inject(
    OneFieldOperationUtilities,
    I18N
)
export class AppendToFieldValuesOperation extends OperationInterface
{
    constructor(
        oneFieldOperationUtilities,
        i18n
    ) {
        super();

        this.oneFieldOperationUtilities = oneFieldOperationUtilities;
        this.i18n = i18n;
    }

    /*public*/ getName()
    {
        return 'append-to-field-values';
    }

    /*public*/ execute(context)
    {
        this.oneFieldOperationUtilities.execute(context);
    }

    /*public*/ getActions(items, operationConfig)
    {
        let actions = [];

        operationConfig.fields.forEach(
            field => {
                const fieldLabel = this.i18n.tr(field.formField.checkboxLabel ? field.formField.checkboxLabel : field.formField.label);

                actions.push({
                    type: 'bulk-operation',
                    operationConfig: operationConfig,
                    label: this.i18n.tr(operationConfig.label, { fieldLabel }),
                    field: field
                });
            }
        );

        return actions;
    }
}
