import {customElement, bindable} from "aurelia-framework";
import {AbstractCell} from './abstract-cell';
import getPropertyByPath from "../../utilities/get-property-by-path";

@customElement('sio-cell-address')
export class Address extends AbstractCell
{
    @bindable column;
    @bindable item;

    itemChanged()
    {
        this.bind();
    }
}
