import {bindable, customElement} from "aurelia-framework"
import {AbstractCell} from "./abstract-cell"
import CodeMirror from "codemirror/lib/codemirror.js"
import "codemirror/lib/codemirror.css"
import "codemirror/mode/twig/twig"
import "codemirror/mode/htmlmixed/htmlmixed"
import "codemirror/addon/runmode/runmode"
import "codemirror/addon/mode/overlay"
import "./multiline.less"

@customElement("sio-cell-codemirror")
export class CodeMirrorCell extends AbstractCell {
    @bindable column
    @bindable item
    preNode

    constructor() {
        super()

        // noinspection JSUnresolvedFunction
        CodeMirror.defineMode("htmltwig", config => CodeMirror.overlayMode(
            CodeMirror.getMode(config, "htmlmixed"),
            CodeMirror.getMode(config, "twig")
        ))
    }

    itemChanged() {
        this.bind()
    }

    valueChanged() {
        // noinspection JSUnresolvedFunction
        CodeMirror.runMode(
            this.value ?? "",
            this.field?.options?.mode ?? "htmltwig",
            this.preNode
        )
    }
}
