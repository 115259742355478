import {
  datesDuration,
  getMemberDepartments,
  getMonthFromDate,
  makeVisibleShifts,
  removeEmployeeFromList,
} from '../calendarFunctions';
import React, { useRef, useEffect, useContext } from 'react';
import { SchedularContext } from './calendarStore';
import { defaultMonthlyHours } from './config';
import PercentageChart from './percentageChart';
import { Tooltip, Whisper, Popover } from 'rsuite';

const OneEmployee = ({ employee, departments, onEmployeeDrop }) => {
  const [
    {
      loading,
      activeCalendarDates,
      filters,
      allShifts,
      activeYear,
      activeWeek,
      duration,
      accommodation,
    },
    setStoreData,
  ] = useContext(SchedularContext);

  if (
    loading ||
    !(allShifts && allShifts[activeYear] && allShifts[activeYear][activeWeek])
  ) {
    return <></>;
  }

  const relDepartments = getMemberDepartments(employee?.id, departments)
    ?.map((d) => d.title)
    ?.join(', ');

  const { start: startDate, end: endDate } = activeCalendarDates;

  const visibleShifts = makeVisibleShifts(
    filters,
    accommodation,
    allShifts[activeYear][activeWeek]
  );

  const employeeShifts = visibleShifts
    .filter((s) => s.employee.find((e) => e.id == employee.id))
    .map((s) => ({
      ...s,
      duration: datesDuration(s.shiftStartDate, s.shiftEndDate, 'hour'),
    }));

  const totalHoursSpent = employeeShifts.reduce(
    (accumulator, currentValue) => accumulator + currentValue.duration,
    0
  );

  const monthlyHours = employee?.monthlyHours || defaultMonthlyHours;
  const viewDuration = duration;

  const totalPossibleHours = Math.ceil(
    viewDuration == 7
      ? monthlyHours / 4
      : viewDuration == 1
      ? monthlyHours / (4 * 5)
      : viewDuration == 3
      ? (monthlyHours * 3) / (4 * 5)
      : viewDuration == 4
      ? (monthlyHours * 4) / (4 * 5)
      : viewDuration == 14
      ? monthlyHours / 2
      : 1
  );

  const percentage =
    100 - ((totalPossibleHours - totalHoursSpent) * 100) / totalPossibleHours;

  const onDragStart = (ev) => {
    const data = {
      id: employee?.id,
      name: employee?.username,
    };
    ev.dataTransfer.setData('daypilot/external-item', JSON.stringify(data));
  };

  const onDragEnd = (ev) => {
    if (ev.dataTransfer.dropEffect === 'move') {
      removeEmployeeFromList(employee?.id, setStoreData);
    }
  };

  const getVacationMetaText = () => {
    const text = employee?.vacationEventDates.map((e) => {
      return e?.metaDescription;
    });
    return text.join('\n');
  };

  return (
    <div
      className={`one-employee  ${
        employee.partiallyAvailable && 'partially-available'
      } ${employee.onVacation && 'on-vacation'}`}
      draggable={'true'}
      onDragStart={onDragStart}
      onDragEnd={onDragEnd}
    >
      <div className="one-employee-main">
        <div className="emp-chart">
          <PercentageChart percentage={percentage.toFixed(1)} />
        </div>
        <div>
          <div>
            {employee?.username}
            {employee?.vacationEventDates?.length > 0 && (
              <Whisper
                trigger="click"
                delayShow={0}
                placement="top"
                speaker={
                  <Popover>
                    {
                      <span
                        dangerouslySetInnerHTML={{
                          __html: getVacationMetaText(),
                        }}
                        style={{ whiteSpace: 'pre-line' }}
                      ></span>
                    }
                  </Popover>
                }
              >
                <span className="ml-2 info-tool-tip">
                  <i className="fa fa-info-circle"></i>
                </span>
              </Whisper>
            )}
            {employee?.overTime > 0 && (
              <Whisper
                trigger="click"
                delayShow={0}
                placement="top"
                speaker={
                  <Popover>
                    {
                      <span style={{ whiteSpace: 'pre-line' }}>
                        Überstunden in {getMonthFromDate(startDate)} :{' '}
                        {employee?.overTime}
                      </span>
                    }
                  </Popover>
                }
              >
                <span className="ml-2 info-tool-tip">
                  <i className="fa fa-info-circle"></i>
                </span>
              </Whisper>
            )}
          </div>
          {relDepartments && (
            <div className="employee-department">{relDepartments}</div>
          )}
        </div>
      </div>
    </div>
  );
};

export default OneEmployee;
