import {customElement, bindable} from "aurelia-framework";
import {AbstractCell} from './abstract-cell';
import * as _ from "lodash";

@customElement('sio-cell-url')
export class Url extends AbstractCell {

    @bindable column;
    @bindable item;

    constructor(i18n) {
        super();

        this.i18n = i18n;
    }

    itemChanged() {
        this.bind();
    }

    unifyValue(value) {
        if (_.isString(value)) {
            value = [value];
        }

        return value;
    }

    getLabel(value) {
        return value.replace(/(^\w+:|^)\/\//, '');
    }
}
