import {customElement, bindable} from "aurelia-framework";
import {AbstractCell} from './abstract-cell';
import moment from 'moment-timezone';

@customElement('sio-cell-age')
export class Age extends AbstractCell
{
    @bindable column;
    @bindable item;

    itemChanged()
    {
        this.bind();
    }

    _getAge(date)
    {
        date = moment(date);

        return moment().diff(date, 'years');
    }
}