import {customElement, bindable} from "aurelia-framework";
import {AbstractCell} from './abstract-cell';

@customElement('sio-cell-due-date')
export class DueDate extends AbstractCell
{
    @bindable column;
    @bindable item;

    itemChanged()
    {
        this.bind();
    }
}