import {DialogController} from 'aurelia-dialog';
import {inject} from "aurelia-framework";
import './generate-dialog.less';

@inject(DialogController)
export class GenerateDialog {
    constructor(controller) {
        this.controller = controller;
    }

    activate(data) {
        this.contextObjectRef = data.formContext;
    }

    ok(event) {
        this.controller.ok([event]);
    }
}
