import getPropertyByPath from "../../utilities/get-property-by-path"
import * as _ from 'lodash';

export class AbstractCell {
    _defaultOptions = {}

    bind() {
        this.value = this.getValue();
        // noinspection JSUnresolvedVariable
        this.options = {...this._defaultOptions, ...this.column?.options}
        this.valueChanged()
    }

    valueChanged() {
    }

    getValue() {
        if (this.column.property == null) {
            return this.item;
        }

        // noinspection JSUnresolvedVariable
        return getPropertyByPath(this.item, this.column.property)
    }
}
