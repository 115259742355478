import React, { useContext, useEffect, useState } from 'react';
import { ServicesContext } from '../schedule-calendar';
import Skeleton from '@yisheng90/react-loading';
import {
  currentWeekDates,
  getCurrentWeekEmployees,
  getDepartments,
  getWeeklyShifts,
  getAccommodations,
} from './calendarFunctions';
import { SchedularContext, updateStore } from './components/calendarStore';
import EmployeeFilter from './components/employeeFilter';
import DepartmentFilter from './components/departmentFilter';
import EmployeesList from './components/employeesList';
import ShiftCalendar from './components/shiftCalendar';
import { defaultDuration } from './components/config';
import AccommodationsFilter from './components/accommodationsFilter';

const CalendarSchedular = () => {
  const { client } = useContext(ServicesContext);
  const [{ loading, accommodation }, setStoreData] = useContext(
    SchedularContext
  );

  useEffect(() => {
    getWeeklyShifts(setStoreData, client, accommodation);
    getCurrentWeekEmployees(setStoreData, client, accommodation);
    getDepartments(setStoreData, client);
    getAccommodations(setStoreData, client);
    setStoreData((s) =>
      updateStore(s, 'activeCalendarDates', currentWeekDates())
    );
    setStoreData((s) => updateStore(s, 'duration', defaultDuration));
  }, []);

  return (
    <div
      className="calendar-schedular-main"
      style={{ opacity: loading ? 0.5 : 1 }}
    >
      {!loading ? (
        <>
          <div className="top-row"></div>
          <div className="schedular-parent">
            <div className="schedular-calendar">
              <ShiftCalendar />
            </div>
            <div className="schedular-employees grid-item">
              <DepartmentFilter />
              <EmployeeFilter />
              {!accommodation && <AccommodationsFilter />}
              <EmployeesList />
            </div>
          </div>
        </>
      ) : (
        <div style={{ background: 'white', padding: '2rem' }}>
          <Skeleton width={'100%'} height={'3rem'} rows={8} />
          <Skeleton width={'80%'} height={'3rem'} rows={6} />
          <Skeleton width={'55%'} height={'3rem'} rows={2} />
        </div>
      )}
    </div>
  );
};

export default CalendarSchedular;
