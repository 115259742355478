import React, { useContext } from 'react';
import { SchedularContext, updateStore } from './calendarStore';
import { CheckPicker } from 'rsuite';
import { ServicesContext } from '../../schedule-calendar';

const AccommodationsFilter = () => {
  const [{ loading, accommodations, filters }, setStoreData] = useContext(
    SchedularContext
  );

  if (!accommodations?.length > 0) {
    return <></>;
  }

  const { i18n } = useContext(ServicesContext);

  const pickerData = accommodations.map((acc) => ({
    label: acc.name,
    value: acc.id,
  }));

  return (
    <div className="schedular-departments grid-item">
      <div className="p-10">
        <label className="mb-2">
          {i18n.tr('shift.shift.fields.companyTitle')}
        </label>
        <CheckPicker
          virtualized
          cleanable={true}
          data={pickerData}
          value={filters?.accommodations || []}
          onChange={(values) => {
            setStoreData((s) =>
              updateStore(s, 'filters.accommodations', values)
            );
          }}
          size="md"
          placeholder="alle"
          className="select-picker-input"
          placement="leftStart"
        />
      </div>
    </div>
  );
};

export default AccommodationsFilter;
