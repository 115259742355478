import * as React from 'react';
const PercentageChart = ({ percentage }) => {
  const remainder = 100 - percentage;
  return (
    <div className="svg-item">
      <svg className="donut" width={'100%'} height={'100%'} viewBox="0 0 40 40">
        <circle cx={20} cy={20} r={15.915} fill="#fff" className="donut-hole" />
        <circle
          cx={20}
          cy={20}
          r={15.915}
          fill="transparent"
          strokeWidth="3.5"
          className="donut-ring"
        />
        <circle
          cx={20}
          cy={20}
          r={15.915}
          strokeWidth="3.5"
          fill="transparent"
          strokeDasharray={`${percentage} ${remainder}`}
          strokeDashoffset="25"
          className="donut-segment donut-segment-4"
        />
        <g className="donut-text donut-text-3">
          <text y="50%" transform="translate(0 2)">
            <tspan x="50%" className="donut-percent" textAnchor="middle">
              {percentage.toString()}{'%'}
            </tspan>
          </text>
        </g>
      </svg>
    </div>
  );
};
export default PercentageChart;
