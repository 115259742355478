import React, { useContext } from 'react';
import { SchedularContext, updateStore } from './calendarStore';
import { CheckPicker } from 'rsuite';
import {
  filteredEmployees as filteredEmployeesLogic,
  makeKeyYearDates,
} from '../calendarFunctions';
import { ServicesContext } from '../../schedule-calendar';

const EmployeeFilter = () => {
  const [
    {
      loading,
      allUsers,
      filters,
      departments,
      allShifts,
      activeYear,
      activeWeek,
      activeCalendarDates,
    },
    setStoreData,
  ] = useContext(SchedularContext);

  if (loading || !allUsers) {
    return <></>;
  }

  const { i18n } = useContext(ServicesContext);

  const { start: startDate, end: endDate } = activeCalendarDates;
  const dateRangeCode = makeKeyYearDates(startDate, endDate);
  const employees = allUsers[dateRangeCode];

  const filteredEmployees = filteredEmployeesLogic(
    employees,
    departments,
    { departments: filters?.departments, employees: [] },
    allShifts,
    activeYear,
    activeWeek
  );

  const pickerData = filteredEmployees.map((emp) => ({
    label: emp.username,
    value: emp.id,
  }));

  return (
    <>
      <div className="p-10">
        <label className="mb-2">{i18n.tr('shift.shift.fields.employee')}</label>
        <CheckPicker
          virtualized
          cleanable={true}
          data={pickerData}
          value={filters?.employees || []}
          onChange={(values) => {
            setStoreData((s) => updateStore(s, 'filters.employees', values));
          }}
          size="md"
          placeholder="alle"
          className="select-picker-input"
          placement="leftStart"
        />
      </div>
    </>
  );
};

export default EmployeeFilter;
