import {inject} from "aurelia-framework";
import {OperationInterface} from "./operation.interface";
import {DialogService} from "aurelia-dialog";
import {BulkOperationQueryCore} from "./utility/bulk-operation-query-core";
import {SelectMediaBundleView} from "./utility/select-media-bundle-view";
import * as _ from "lodash";
import {I18N} from "aurelia-i18n";

@inject(
    DialogService,
    BulkOperationQueryCore,
    I18N
)
export class AddToExistingMediaBundle extends OperationInterface
{
    constructor(
        dialogService,
        bulkOperationQueryCore,
        i18n
    ) {
        super();

        this.dialogService = dialogService;
        this.bulkOperationQueryCore = bulkOperationQueryCore;
        this.i18n = i18n;
    }

    /*public*/ getName()
    {
        return 'add-to-existing-media-bundle';
    }

    /*public*/ execute(context)
    {
        return this.dialogService.open({
            viewModel: SelectMediaBundleView,
            model: {
                title: 'media-database.bulk-operation.add-to-existing-media-bundle',
                onSave: this.onSaveCallback(context).bind(this)
            }
        }).whenClosed();
    }


    /*public*/ getActions(items, operationConfig)
    {
        return [
            {
                type: 'bulk-operation',
                operationConfig: operationConfig,
                label: this.i18n.tr('media-database.bulk-operation.add-to-existing-media-bundle'),
            }
        ];
    }

    /*private*/ onSaveCallback(context)
    {
        return (mediaBundle) => {
            return this.bulkOperationQueryCore.query(
                context.operationConfig.type,
                {
                    items: context.items,
                    mediaBundle: mediaBundle
                }
            )
        };
    }
}
