

export class EmptyArrayValueConverter
{
    toView(value)
    {
        if (value == null) {
            return [];
        }

        return value;
    }

    fromView(value)
    {
        if (value instanceof Array && value.length === 0) {
            return null;
        }

        return value;
    }
}
