import {bindable, bindingMode, customElement, inject, noView} from "aurelia-framework"
import React from "react"
import {render} from "react-dom"
import {Provider} from "react-redux"
import store from "../store/store"
import AssignForm from "./components/assign/form"
import {EventAggregator} from "aurelia-event-aggregator";
import RSuiteLocale from "../i18n/rsuite-locale";

@noView()
@inject(Element, EventAggregator)
@customElement("banking-api-assign")
export default class BankingApiAssign {
    @bindable({defaultBindingMode: bindingMode.oneTime})  id
    @bindable() dialogController

    constructor(container, ea) {
        this.container = container
        this.ea = ea
    }

    closeModal() {
        this.dialogController.ok()
        this.ea.publish('sio_form_post_submit', {config: {modelId: "banking-api/transaction"}})
    }

    async attached() {
        render(
            (
                <RSuiteLocale>
                    <Provider store={store}>
                        <AssignForm id={this.id} closeModal={this.closeModal.bind(this)}/>
                    </Provider>
                </RSuiteLocale>
            ),
            this.container
        )
    }
}



