import {customElement, bindable} from "aurelia-framework";
import {AbstractCell} from './abstract-cell';

@customElement('sio-cell-multiline')
export class Multiline extends AbstractCell
{
    @bindable column;
    @bindable item;

    //Specifically set clipped only for cells
    @bindable clipped = false;

    itemChanged()
    {
        this.bind();
    }

    setClipped(clipped)
    {
        this.clipped = clipped;
    }
}
