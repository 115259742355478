import {bindable, LogManager, customElement} from 'aurelia-framework';
import * as _ from "lodash";
import "jquery-ui/widgets/sortable";
import "@selectize/selectize";
import "@selectize/selectize/dist/css/selectize.bootstrap3.css";
import $ from "jquery";
import {fixSelectizeKeyboard} from "./selectize-hacks";

const logger = LogManager.getLogger('AutofillingChoiceElement');

@customElement('sio-autofilling-choice-input')
export class AutofillingChoiceElement
{
    @bindable config;
    @bindable choices;
    @bindable({defaultBindingMode: 2}) value;

    bind()
    {
        this._reloadSelectize();
    }

    choicesChanged()
    {
        this._reloadSelectize();
    }

    configChanged()
    {
        this._reloadSelectize();
    }

    valueChanged()
    {
        this._reloadSelectize();
    }

    _reloadSelectize()
    {
        if (!this.select) {
            return;
        }

        if (this._selectize) {
            this._selectize.destroy();
            this._selectize = null;
        }

        const choiceElement = this;

        let plugins = [];
        if (this.config) {
            if (this.config.multiple || !this.config.required) {
                plugins.push('remove_button');
            }
        }

        let maxItems = null;
        if (!this.config.multiple) {
            maxItems = 1;
        } else if (this.config.maxItems) {
            maxItems = this.config.maxItems;
        }

        let matchSearchFromStart = this.config?.matchSearchFromStart || false;
        this._selectize = $(this.select)
            .selectize({
                plugins: plugins,
                valueField: 'value',
                labelField: 'label',
                searchField: 'label',
                preload: 'focus',
                selectOnTab: this.config.selectOnTab || false,
                maxItems: maxItems,
                load: (search, callback) => {
                    let items = _.map(this.choices, (element) => {
                        return {
                            label: element.label,
                            value: this._modelReferenceToViewReference(element.value),
                        };
                    });

                    callback(items);
                },
                onChange: this._valueChangedInSelectize?.bind(this),
                onInitialize: function() {
                    if (null == choiceElement.value ||
                        (_.isArray(choiceElement.value) && choiceElement.value.length == 0)) {
                        return;
                    }

                    let initialValues = choiceElement.value;

                    if (!_.isArray(initialValues)) {
                        initialValues = [initialValues];
                    }

                    let items = _.map(choiceElement.choices, (element) => {
                        return {
                            label: element.label,
                            value: choiceElement._modelReferenceToViewReference(element.value)
                        };
                    });

                    this.addOption(items);
                    this.setValue(choiceElement._getChoiceValue(), true);
                },
                score: function(search) {
                    let score = this.getScoreFunction(search);
                    return function(item) {
                        if(matchSearchFromStart){
                            return item.label.toLowerCase().indexOf(search.toLowerCase()) === 0 ? score(item) : 0
                        }else{
                            return score(item)
                        }
                    };
                }
            })[0].selectize
        ;

        fixSelectizeKeyboard(this._selectize, this.config);
        this._keepFocusOnBackspace(this._selectize);
        this._selectOnNKeys(this._selectize, this.config);
    }

    _keepFocusOnBackspace(selectize)
    {
        const defaultKeyDown = selectize.onKeyDown?.bind(selectize);

        selectize.onKeyDown = (e) => {
            defaultKeyDown(e);

            // 8 - backspace
            if (8 === e.keyCode) {
                const inQueryingMode = selectize.lastQuery !== "" && selectize.lastQuery;

                if (!inQueryingMode) {
                    setTimeout(() => {
                        logger.debug("keep focus", this._selectize);
                        $(this._selectize.$control_input).click();
                    });
                }
            }
        };
    }

    _selectOnNKeys(selectize, config)
    {
        if (!config.autofillKeystrokeCount) {
            return;
        }

        const defaultKeyUp = selectize.onKeyUp?.bind(selectize);

        selectize.onKeyUp = (e) => {
            let nextInput = undefined;
            const $canfocus = $('input');
            let index = $canfocus.index(selectize.$control_input) + 1;
            if (index < $canfocus.length) {
                nextInput = $canfocus.eq(index);
            }

            setTimeout(() => {
                const inQueryingMode =
                    selectize.lastQuery !== "" &&
                    selectize.lastQuery != null;

                if (inQueryingMode && selectize.lastQuery.length === config.autofillKeystrokeCount) {
                    if (selectize.currentResults.items.length == 1) {
                        logger.debug("Select most matching value", selectize.currentResults.items[0]);

                        this.value = this._viewReferenceToModelReference(selectize.currentResults.items[0].id);

                        if ((selectize.isFocused || selectize.isBlurring) && nextInput) {
                            nextInput.focus();
                        }
                    }
                }
            });

            defaultKeyUp(e);
        };
    }

    _valueChangedInSelectize(newValue)
    {
        const __transformNewValue = (newValue) => {
            if (newValue === '') {
                newValue = null;
            }

            if (newValue) {
                if (_.isArray(newValue)) {
                    newValue = _.map(newValue, element => this._viewReferenceToModelReference(element));
                } else {
                    newValue = this._viewReferenceToModelReference(newValue);
                }
            }

            return newValue;
        };

        newValue = __transformNewValue(newValue);

        this.value = newValue;
    }

    _getChoiceValue()
    {
        let choiceValue = [];

        if (_.isArray(this.value)) {
            _.each(this.value, (element, index) => {

                choiceValue.push(this._modelReferenceToViewReference(element));
            });
        } else {
            choiceValue.push(this._modelReferenceToViewReference(this.value));
        }

        return choiceValue;
    }

    _viewReferenceToModelReference(element)
    {
        if (element.startsWith('modelId|')) {
            let parts = element.split('|');

            return {
                id: parts[2],
                modelId: parts[1]
            };
        }

        return element;
    }

    _modelReferenceToViewReference(element)
    {
        if (_.isObject(element)) {
            return 'modelId|' + element.modelId + '|' + element.id;
        } else {
            return element;
        }
    }
}
