import {inject} from "aurelia-framework";
import {DialogService} from "aurelia-dialog";
import {OneFieldView} from "./one-field-view";
import {BulkOperationQueryCore} from "./bulk-operation-query-core";
import * as _ from "lodash";

@inject(
    DialogService,
    BulkOperationQueryCore
)
export class OneFieldOperationUtilities
{
    constructor(
        dialogService,
        bulkOperationQueryCore
    ) {
        this.dialogService = dialogService;
        this.bulkOperationQueryCore = bulkOperationQueryCore;
    }

    /*public*/ execute(context)
    {
        return this.dialogService.open({
            viewModel: OneFieldView,
            model: {
                field: context.field,
                title: context.label,
                onSave: this.onSaveCallback(context).bind(this)
            }
        }).whenClosed();
    }

    /*private*/ onSaveCallback(context)
    {
        return (value) => {
            return this.bulkOperationQueryCore.query(
                context.operationConfig.type,
                {
                    field: context.field.fieldName,
                    items: context.items,
                    value: value
                }
            )
        };
    }
}
