import {customElement, bindable} from "aurelia-framework";
import {AbstractCell} from './abstract-cell';

@customElement('sio-cell-price-set')
export class PriceSet extends AbstractCell
{
    @bindable column;
    @bindable item;

    itemChanged()
    {
        this.bind();
    }

    bind()
    {
        this.value = this.getValue();
        this.options = Object.assign({}, this._defaultOptions, this.column.options);

        if (this.value.type === 'dateAndCount' || this.value.type === 'count') {

            this.columns = this._getColumns(this.value);
            this.priceLines = this._getPriceLines(this.value);
        }
    }

    _getColumns(value)
    {
        const columns = new Map();

        for (let priceLine of value.priceLines) {

            if (!priceLine.countFrom || !priceLine.countTo) {
                continue;
            }

            let key = priceLine.countFrom + '-' + priceLine.countTo;

            if (!columns.has(key)) {
                columns.set(key, {
                    min: priceLine.countFrom,
                    max: priceLine.countTo,
                });
            }
        }

        return Array.from(columns.values()).sort((a, b) => a.min - b.min);
    }

    _getPriceLines(value)
    {
        let priceLines = new Map();

        for (let priceLine of value.priceLines) {

            if (!priceLine.countFrom || !priceLine.countTo) {
                continue;
            }

            let index = this._findMatchingColumn(priceLine.countFrom, priceLine.countTo);

            if (index == null) {
                continue;
            }

            let key = priceLine.from + '-' + priceLine.to;

            let value = priceLines.get(key);

            if (!value) {
                value = {};

                value.from = priceLine.from;
                value.to = priceLine.to;

                value.prices = [];
            }

            value.prices[index] = {
                retailPrice: priceLine.retailPrice,
                purchasePrice: priceLine.purchasePrice
            };

            priceLines.set(key, value);
        }

        return Array.from(priceLines.values());
    }

    _findMatchingColumn(min, max)
    {
        for (let i = 0; i < this.columns.length; i++) {

            if (this.columns[i].min == min && this.columns[i].max == max) {
                return i;
            }
        }

        return null;
    }
}
