import React, {useContext, useEffect} from "react"
import {useSelector} from "react-redux"
import AureliaContext from "../../../utilities/aurelia-context"
import {selectSaveBody} from "../../store/assign-slice";
import {useAssignMutation} from "../../store/bank-api";
import {Button} from "rsuite";
import {useStyles} from "../../../costs/origin/constants";

export default function SaveButton({id, closeModal}) {
    const {i18n} = useContext(AureliaContext)
    const body = useSelector(selectSaveBody)
    const [doSave, {isLoading, isSuccess}] = useAssignMutation()
    const {danger} = useStyles()

    useEffect(() => {
        if (isSuccess) {
            closeModal()
        }
    }, [isSuccess])

    return (
        <Button loading={isLoading} className={danger} onClick={() => doSave({id, body})}>
            {i18n.tr("fin-api.assign")}
        </Button>
    )
}
