import { inject } from 'aurelia-framework';
import { Client } from '../../../api/client';
import { FlashService } from '../../../flash/flash-service';
import { ConfigurationLoader } from '../../../form/loader/configuration-loader';
import { FormServiceFactory } from '../../../form/service/form-service-factory';
import { DialogController } from 'aurelia-dialog';

@inject(Client, FlashService, ConfigurationLoader, FormServiceFactory, DialogController)
export class CalendarFormDialog {
    form;
    createFormId;
    referenceModelId;
    constructor(client, flash, configurationLoader, formServiceFactory, dialogController) {
        this.client = client;
        this.flash = flash;
        this.configurationLoader = configurationLoader;
        this.formServiceFactory = formServiceFactory;
        this.dialogController = dialogController;

        this.submit = this.submit.bind(this);
    }

    async activate(context) {
        const { resource, start, end } = context.data;
        this.createFormId  = context.createFormId;
        this.referenceModelId  = context.referenceModelIdRef;
        const data = {
            guideLink: { id: resource },
            startDate: start.value,
            endDate: end.value,
            id: resource,
        };

        this.formConfig = await this.configurationLoader.get(this.createFormId, data);
        const fields = this.formConfig.fields;
        if (fields && fields.length > 1) {
            fields[1].default = { id: resource, modelId: this.referenceModelId };

            const startDateIndex = fields.findIndex(field => field.property === 'startDate');
            const endDateIndex = fields.findIndex(field => field.property === 'endDate');

            if (startDateIndex !== -1) {
                fields[startDateIndex].default = start.value;
            }

            if (endDateIndex !== -1) {
                fields[endDateIndex].default = end.value;
            }
        }
    }

    async submit() {
        const data = this.form.formService.getValue();
        const formData = await this.client.post(this.createFormId, data);
        this.dialogController.ok(formData);
        this.flash.success('sio.successful');
    }
}
