import React, { useContext } from 'react';
import { SchedularContext, updateStore } from './calendarStore';
import { CheckPicker } from 'rsuite';
import { ServicesContext } from '../../schedule-calendar';

const DepartmentFilter = () => {
  const [{ loading, departments, filters }, setStoreData] = useContext(
    SchedularContext
  );

  if (!departments?.length > 0) {
    return <></>;
  }

  const pickerData = departments.map((dep) => ({
    label: dep.title,
    value: dep.id,
  }));

  const { i18n } = useContext(ServicesContext);

  return (
    <div className="schedular-departments grid-item">
      <div className="p-10">
        <label className="mb-2">
          {i18n.tr('bw-sozialwerk.user.fields.department')}
        </label>
        <CheckPicker
          virtualized
          cleanable={true}
          data={pickerData}
          value={filters?.departments || []}
          onChange={(values) => {
            setStoreData((s) => updateStore(s, 'filters.departments', values));
          }}
          size="md"
          placeholder="alle"
          className="select-picker-input"
          placement="leftStart"
        />
      </div>
    </div>
  );
};

export default DepartmentFilter;
