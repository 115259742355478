import {inject} from "aurelia-framework";
import {Client} from "../../../api/client";
import {FlashService} from "../../../flash/flash-service";
import {EventAggregator} from "aurelia-event-aggregator";
import * as _ from "lodash";

@inject(
    Client,
    FlashService,
    EventAggregator
)
export class BulkOperationQueryCore
{
    constructor(
        client,
        flash,
        ea
    ) {
        this.client = client;
        this.flash = flash;
        this.ea = ea;
    }

    /*public*/ query(operationName, body)
    {
        return this.client.patch('bulk-operations/' + operationName, body)
            .then(response => {
                this.notifyModelsAboutChange(body.items);
                this.flash.success('sio.successful');

                return response;
            }, error => {
                this.flash.error('sio.failed');

                throw error;
            })
        ;
    }

    /*private*/ notifyModelsAboutChange(items)
    {
        let touchedModels = _.map(
            _.uniqBy(items, 'modelId'),
            (item) => {
                return item.modelId;
            }
        );

        _.each(touchedModels, (modelId) => {
            this.ea.publish(
                'sio_form_post_submit',
                {config: {modelId: modelId}}
            );
        });
    }
}
