import {customElement, bindable} from "aurelia-framework";
import {AbstractCell} from './abstract-cell';

@customElement('sio-cell-money')
export class Money extends AbstractCell
{
    @bindable column;
    @bindable item;

    itemChanged()
    {
        this.bind();
    }
}
