import React, { useContext, useEffect, useState } from 'react';
import { Button, Modal, Input } from 'rsuite';
import { addUserInShift, updateShiftWithEmployee } from '../calendarFunctions';
import { ServicesContext } from '../../schedule-calendar';

const InputField = ({ value, setValue, placeholder }) => {
  return (
    <Input
      as="textarea"
      rows={3}
      style={{ width: '100%', marginTop: '12px' }}
      placeholder={placeholder}
      value={value}
      required={true}
      onChange={(v) => setValue(v)}
    />
  );
};

const ViolationMessage = ({
  condition,
  message,
  value,
  setValue,
  placeholder,
}) => {
  return condition ? (
    <div>
      <div>{message}</div>
      <div>
        <InputField
          value={value}
          setValue={setValue}
          placeholder={placeholder}
        />
      </div>
    </div>
  ) : (
    <></>
  );
};

const ValidationModal = ({
  setStoreData,
  activeYear,
  activeWeek,
  showModal,
  setShowModal,
  activeResponse,
  setActiveResponse,
}) => {
  const { client, flash, i18n } = useContext(ServicesContext);

  const [overtimeText, setOvertimeText] = useState('');
  const [restingPeriodViolation, setRestingPeriodViolation] = useState('');
  const [isFreeSundayAvailable, setIsFreeSundayAvailable] = useState('');
  const [youthWorkingHoursViolation, setYouthWorkingHoursViolation] = useState(
    ''
  );
  const [isFullTime, setIsFullTime] = useState('');

  const sendUpdateWithErrorException = async (e) => {
    e.preventDefault();
    const conditions = [
      { subject: 'isOverTime', note: overtimeText },
      {
        subject: 'restingPeriodViolation',
        note: restingPeriodViolation,
      },
      {
        subject: 'isFreeSundayAvailable',
        note: isFreeSundayAvailable,
      },
      {
        subject: 'youthWorkingHoursViolation',
        note: youthWorkingHoursViolation,
      },
      { subject: 'isFullTime', note: isFullTime },
    ];
    const tasks = conditions
      .filter((item) => item.note)
      .map((item) => ({ subject: item.subject, note: item.note }));

    if (tasks.length > 0) {
      const response = await addUserInShift(
        activeResponse?.user,
        activeResponse?.shift,
        client,
        tasks
      );
      if (response.ok) {
        updateShiftWithEmployee(
          { id: activeResponse?.user, name: activeResponse?.username },
          activeResponse?.shift,
          setStoreData,
          activeYear,
          activeWeek
        );
        setActiveResponse({});
        setShowModal(false);
      } else {
      }
    } else {
      setActiveResponse({});
      //   setShowModal(false);
    }
  };

  return (
    <Modal
      open={showModal}
      onClose={() => {
        setShowModal(false);
      }}
    >
      <Modal.Header>
        <Modal.Title>
          <span style={{ fontSize: '20px' }}>{i18n.tr('shift.shift.prompt.heading')}</span>
        </Modal.Title>
      </Modal.Header>
      <form onSubmit={sendUpdateWithErrorException}>
        <Modal.Body>
          <div
            style={{ display: 'flex', flexDirection: 'column', gap: '12px' }}
          >
            <ViolationMessage
              condition={activeResponse?.isOverTime > 0}
              message={i18n.tr('shift.shift.prompt.overtime')}
              value={overtimeText}
              setValue={setOvertimeText}
              placeholder=""
            />
            <ViolationMessage
              condition={activeResponse?.restingPeriodViolation === true}
              message={i18n.tr('shift.shift.prompt.restingPeriod')}
              value={restingPeriodViolation}
              setValue={setRestingPeriodViolation}
              placeholder=""
            />
            <ViolationMessage
              condition={activeResponse?.isFreeSundayAvailable === false}
              message={i18n.tr('shift.shift.prompt.freeSundays')}
              value={isFreeSundayAvailable}
              setValue={setIsFreeSundayAvailable}
              placeholder=""
            />
            <ViolationMessage
              condition={activeResponse?.youthWorkingHoursViolation === true}
              message={i18n.tr('shift.shift.prompt.youthWorkTime')}
              value={youthWorkingHoursViolation}
              setValue={setYouthWorkingHoursViolation}
              placeholder=""
            />
            <ViolationMessage
              condition={activeResponse?.isFullTime === false}
              message={i18n.tr('shift.shift.prompt.userOnVacation')}
              value={isFullTime}
              setValue={setIsFullTime}
              placeholder=""
            />
          </div>
        </Modal.Body>

        <Modal.Footer>
          <Button appearance="primary" className="btn-primary" type={'submit'}>
            Senden
          </Button>
          <Button onClick={() => setShowModal(false)} appearance="default">
            Cancel
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default ValidationModal;
