import React, { useContext } from 'react';
import { SchedularContext, updateStore } from './calendarStore';
import {
  currentWeekDates,
  weeksMath,
  getShiftsIfNotThere,
  refreshEmployeeList,
  getYearWeekFromDate,
  formatDate,
  makeKeyYearDates,
  getStartOfWeekFromDate,
} from '../calendarFunctions';
import { ServicesContext } from '../../schedule-calendar';
import { DateRangePicker, CustomProvider } from 'rsuite';
import deDE from 'rsuite/esm/locales/de_DE';

const CalendarTopRow = ({}) => {
  const [
    {
      loading,
      allShifts,
      allUsers,
      activeCalendarDates,
      duration,
      activeYear,
      shiftsLoading,
      activeWeek,
      accommodation,
    },
    setStoreData,
  ] = useContext(SchedularContext);

  if (
    loading ||
    !(allShifts && allShifts[activeYear] && allShifts[activeYear][activeWeek])
  ) {
    return <></>;
  }

  const { client, flash, i18n } = useContext(ServicesContext);

  const { start: startDate, end: endDate } = activeCalendarDates;

  const onDurationChange = async (e) => {
    const newValue = Number(e.target.value);
    setStoreData((s) => updateStore(s, 'duration', newValue));

    if (newValue == 7) {
      const newCaledarDates = {
        start: getStartOfWeekFromDate(startDate),
        end: weeksMath(getStartOfWeekFromDate(startDate), 'add', newValue - 1),
      };
      await fetchAndUpdateData(newCaledarDates.start, newCaledarDates.end);
      setStoreData((d) =>
        updateStore(d, 'activeCalendarDates', newCaledarDates)
      );
    }
  };

  const fetchAndUpdateData = async (start, end, isRefresh = false) => {
    await refreshEmployeeList(
      start,
      end,
      allShifts,
      allUsers,
      client,
      setStoreData,
      isRefresh
    );
    await getShiftsIfNotThere(
      start,
      end,
      allShifts,
      client,
      setStoreData,
      isRefresh,
      accommodation
    );
  };

  const nextCalendarDays = async () => {
    const newCaledarDates = {
      start: weeksMath(startDate, 'add', duration),
      end: weeksMath(endDate, 'add', duration),
    };
    await fetchAndUpdateData(newCaledarDates.start, newCaledarDates.end);
    setStoreData((d) => updateStore(d, 'activeCalendarDates', newCaledarDates));
  };
  const prevCalendarDays = async () => {
    const newCaledarDates = {
      start: weeksMath(startDate, 'minus', duration),
      end: weeksMath(
        weeksMath(startDate, 'minus', duration),
        'add',
        duration - 1
      ),
    };
    await fetchAndUpdateData(newCaledarDates.start, newCaledarDates.end);
    setStoreData((d) => updateStore(d, 'activeCalendarDates', newCaledarDates));
  };
  const setThisWeek = async () => {
    const { start, end } = currentWeekDates();
    await getShiftsIfNotThere(
      start,
      end,
      allShifts,
      client,
      setStoreData,
      false,
      accommodation
    );
    setStoreData((s) =>
      updateStore(s, 'activeCalendarDates', currentWeekDates())
    );
  };

  const refreshData = async () => {
    const newCaledarDates = {
      start: weeksMath(startDate, 'minus', 0),
      end: weeksMath(weeksMath(startDate, 'minus', 0), 'add', duration - 1),
    };
    await fetchAndUpdateData(newCaledarDates.start, newCaledarDates.end, true);
  };

  const generateShifts = async () => {
    setStoreData((s) => updateStore(s, 'shiftsLoading', true));
    const data = await client.post('shift/shift-setting/trigger', {
      fromDate: getYearWeekFromDate(startDate),
      toDate: getYearWeekFromDate(endDate),
    });
    if (data) {
      flash.success('Aktion erfolgreich durchgeführt');
    }
    refreshData();
    setStoreData((s) => updateStore(s, 'shiftsLoading', false));
  };

  const onCalendarDateRangeChange = async (value) => {
    const [newStartDate, newEndDate] = value;
    const newCaledarDates = {
      start: formatDate(newStartDate),
      end: formatDate(newEndDate),
    };

    console.log(newCaledarDates, value, 'test');
    await fetchAndUpdateData(newCaledarDates.start, newCaledarDates.end);
    setStoreData((d) => updateStore(d, 'activeCalendarDates', newCaledarDates));
  };

  return (
    <div className="calendar-top-row">
      <div className="nav-buttons">
        <div>
          {shiftsLoading ? (
            <div className="nav-loading">Loading...</div>
          ) : (
            <>
              <div className="nav-button" onClick={() => prevCalendarDays()}>
                {'<'}
              </div>
              <div className="nav-button" onClick={() => nextCalendarDays()}>
                {'>'}
              </div>
              <div className="nav-button" onClick={() => refreshData()}>
                <i
                  className="fas fa-refresh"
                  title={i18n.tr('shift.shift.calender.refresh')}
                />
              </div>
              <div className="nav-button" onClick={() => setThisWeek()}>
                {i18n.tr('shift.shift.calender.today')}
              </div>
            </>
          )}
        </div>
        <div>
          <DateRangePicker
            oneTap
            showOneCalendar
            hoverRange="week"
            format="dd.MM.yyyy"
            cleanable={false}
            ranges={[]}
            value={[new Date(startDate), new Date(endDate)]}
            locale={deDE}
            isoWeek={true}
            onChange={onCalendarDateRangeChange}
            loading={shiftsLoading}
          />
        </div>
        <div>
          {shiftsLoading ? (
            <div className="nav-loading">Loading...</div>
          ) : (
            <div className="nav-button" onClick={() => generateShifts()}>
              <span>{i18n.tr('shift.shift-setting.prepareShift')}</span>
            </div>
          )}
          <select
            name="duration"
            className="sio-select"
            id="duration"
            value={duration}
            onChange={onDurationChange}
          >
            <option value="1">1</option>
            <option value="7">7</option>
          </select>
        </div>
      </div>
    </div>
  );
};

export default CalendarTopRow;
