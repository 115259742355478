import {customElement, bindable, inject} from "aurelia-framework";
import {AbstractCell} from './abstract-cell';
import * as _ from 'lodash';
import {ChoiceLoader} from "../../choice/loader/choice-loader";
import {I18N} from "aurelia-i18n";

@customElement('sio-cell-email')
@inject(I18N)
export class Email extends AbstractCell {
    @bindable column;
    @bindable item;

    constructor(i18n) {
        super();

        this.i18n = i18n;
    }

    itemChanged() {
        this.bind();
    }

    unifyValue(value) {
        if (value == null || value == '') {
            return [];
        }

        if (_.isString(value)) {
            value = [value];
        }

        let returnValue = [];

        for (let email of value) {
            if (_.isString(email)) {
                returnValue.push({
                    email: email,
                });
            } else {
                returnValue.push(email);
            }
        }

        return returnValue;
    }

    getType(type)
    {
        //Todo load from core
        if (type === 'private') {
            return this.i18n.tr('base.email.private');
        } else if (type === 'business') {
            return this.i18n.tr('base.email.business');
        }

        return '';
    }
}
