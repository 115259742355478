import $ from 'jquery';
import 'fullcalendar';
import 'fullcalendar/dist/locale-all';
import {customElement, bindable, inject} from 'aurelia-framework';
import {Client} from '../api/client';
import {MongoDataLoader} from "../table/loader/mongo-data-loader";
import {FlashService} from "../flash/flash-service";
import {I18N} from "aurelia-i18n";

@customElement('sio-calendar')
@inject(MongoDataLoader, FlashService, I18N)
export class Calendar {
    @bindable config = {};
    @bindable callback;

    constructor(loader, flashService, i18n) {
        this.loader = loader;
        this.flashService = flashService;
        this.i18n = i18n;
    }

    attached() {
        $(this.calendar).fullCalendar({
            header: {
                left: 'prev,next today',
                center: 'title',
                right: this.config.views ? this.config.views : 'month,agendaWeek'
            },
            defaultView: this.config.defaultView ? this.config.defaultView : 'month',
            locale: 'de',
            events: this.loadEvents.bind(this),
            eventRender: function(event, element) {
                $(element).tooltip({title: event.title, container: 'body'});
            },
            timezone: 'local'
        });
    }

    reload()
    {
        $(this.calendar).fullCalendar('refetchEvents');
    }

    loadEvents(start, end, timezone, callback) {

        if (this.callback) {
            this.callback(this.config, start, end).then(events => {
                callback(events);
            });
            return;
        }

        let conditions = {};

        if (this.config.startDate && this.config.endDate) {
            conditions[this.config.startDate] = {
                '$lte': end.format('YYYY-MM-DDTHH:mm:ssZ')
            };

            conditions[this.config.endDate] = {
                '$gte': start.format('YYYY-MM-DDTHH:mm:ssZ')
            };
        }  else if (this.config.date) {
            conditions[this.config.date] = {
                '$gte': start.format('YYYY-MM-DDTHH:mm:ssZ'),
                '$lte': end.format('YYYY-MM-DDTHH:mm:ssZ'),
            };
        }

        if (this.config.additionalFetchConditions) {
            conditions = {
                '$and': [
                    conditions,
                    this.config.additionalFetchConditions
                ]
            };
        }

        let request = {
            conditions: conditions,
            embeds: this.config.embeds ? this.config.embeds : []
        };

        this.loader.getData(this.config.modelId, request).then(data => {

            let events = [];

            for (let i = 0; i < data.items.length; i++) {

                let item = data.items[i];

                let event = {
                    allDay: true,
                    title: this.i18n.tr(this.config.title, {sprintf: item}),
                };

                if (this.config.startDate) {
                    event.start = item[this.config.startDate];
                    event.end = item[this.config.endDate];
                } else if (this.config.date) {
                    event.start = item[this.config.date];
                    event.end = item[this.config.date];
                }

                events.push(event);

            }

            callback(events);

        }, error => {

            this.flashService.error('list.load_error');
            callback([]);
        });
    }


}