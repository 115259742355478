import {customElement, bindable} from "aurelia-framework";
import {AbstractCell} from './abstract-cell';

@customElement('sio-cell-opening-times')
export class Email extends AbstractCell {
    @bindable column;
    @bindable item;

    itemChanged() {
        this.bind();
    }
}
