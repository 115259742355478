import {inject} from 'aurelia-framework';
import {DialogService} from 'aurelia-dialog';
import {Client} from '../../api/client';
import {FlashService} from '../../flash/flash-service';
import {I18N} from 'aurelia-i18n';
import {ArtificialIntelligence} from "../helper/artificial-intelligence";
import {UserClient} from "../../api/user-client";

@inject(
    DialogService,
    Client,
    FlashService,
    I18N,
    ArtificialIntelligence,
    UserClient,
)
export class ArtificialIntelligencePlugin
{
    constructor(
        dialogService,
        client,
        flash,
        i18n,
        artificialIntelligence,
        userClient,
    )
    {
        this.dialogService = dialogService;
        this.client = client;
        this.flash = flash;
        this.i18n = i18n;
        this.artificialIntelligence = artificialIntelligence;
        this.userClient = userClient;
    }

    initialize(tinymce, field)
    {
        //This gets overwritten in callback so declaring locally
        const artificialIntelligence = this.artificialIntelligence;
        const flash = this.flash;
        const i18n = this.i18n;
        const userClient = this.userClient;

        const isArtificialIntelligenceEnabled = (userClient?.getUser()?.
            instance?.settings?.artificialIntelligenceInstanceSettings?.isActive) ?? false;

        if (false === isArtificialIntelligenceEnabled) {
            return;
        }

        tinymce.PluginManager.add('artificialintelligencegenerate', function(editor) {
            // Add a button that opens a window
            editor.addButton('artificialintelligencegenerate', {
                tooltip: i18n.tr('artificial-intelligence.form.generation-request.action'),
                icon: ' overwrite-fa-font fa fa-wand-magic-sparkles',
                onclick: async () => {
                    const {id, modelId} = field?.parent?.contextObjectRef ?? {};

                    try {
                        const res = await artificialIntelligence.generate({id: id, modelId: modelId});

                        if (artificialIntelligence.validateResponseObj(res)) {
                            return;
                        }

                        editor.insertContent(res.response);
                    } catch (error) {
                        console.error(error);
                        flash.error(error?.localizedMessage ?? error?.message ?? error ?? "Error");
                    }
                }
            });
        });
    }

}
