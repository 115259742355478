import {
    bindable,
    bindingMode,
    customElement,
    inject,
} from 'aurelia-framework';
import { AureliaConfiguration } from 'aurelia-configuration';
import { DialogService } from 'aurelia-dialog';
import { render, unmountComponentAtNode } from 'react-dom';
import Client from '../../api/client';
import { ConfigurationLoader } from '../../form/loader/configuration-loader';
import { FlashService } from '../../flash/flash-service';
import { UrlUtils } from '../../utilities/url-utils';
import { SubmitHandler } from '../../form/submit/submit-handler';
import { AuthTokenStorage } from '../../auth/auth-token-storage';
import React, { useContext } from 'react';
import { I18N } from 'aurelia-i18n';
import { EventAggregator } from 'aurelia-event-aggregator';
import './schedule-calendar.less';
import CalendarSchedular from './schedular/CalendarSchedular';
import SchedularProvider from './schedular/components/calendarStore';
@customElement('sio-schedule-calendar')
@inject(
    Element,
    Client,
    ConfigurationLoader,
    FlashService,
    UrlUtils,
    DialogService,
    SubmitHandler,
    AuthTokenStorage,
    AureliaConfiguration,
    I18N,
    EventAggregator
)
export class ParticipantsListView {
    @bindable({ defaultBindingMode: bindingMode.oneTime }) accommodation;

    constructor(
        element,
        client,
        configLoader,
        flash,
        urlUtils,
        dialog,
        apiSubmit,
        token,
        aureliaConfig,
        i18n,
        ea
    ) {
        this.services = {};
        this.services.client = client;
        this.services.configLoader = configLoader;
        this.services.flash = flash;
        this.services.urlUtils = urlUtils;
        this.services.dialog = dialog;
        this.services.submit = apiSubmit;
        this.services.token = token;
        this.services.aureliaConfig = aureliaConfig;
        this.services.i18n = i18n;
        this.services.ea = ea;
        this.container = element;
    }

    reactRender() {
        if (this.container) {
            render(
                <ServicesContext.Provider value={this.services}>
                    <SchedularProvider
                        value={{ accommodation: this.accommodation || undefined }}
                    >
                        <CalendarSchedular />
                    </SchedularProvider>
                </ServicesContext.Provider>,
                this.container
            );
        }
    }

    attached() {
        this.reactRender();
    }

    detached() {
        unmountComponentAtNode(this.container);
    }
}

export const ServicesContext = React.createContext({});
