import React, { useContext, useRef, useState, useEffect } from 'react';
import { SchedularContext, updateStore } from './calendarStore';
import { DayPilot, DayPilotCalendar } from 'daypilot-pro-react';
import {
  makeShiftEvents,
  updateShiftWithEmployee,
  makeVisibleShifts,
  addUserInShift,
  getEmployeeName,
  removeEmployeeFromShift,
  removeEmployeeFromStore,
  makeKeyYearDates,
} from '../calendarFunctions';
import { ServicesContext } from '../../schedule-calendar';
import CalendarTopRow from './calendarTopRow';
import { businessEndsHour, dayBeginsHour, businessBeginsHour } from './config';
import ValidationModal from './ValidationModal';

const ShiftCalendar = () => {
  const [
    {
      loading,
      allShifts,
      allUsers,
      filters,
      activeCalendarDates,
      duration,
      activeYear,
      activeWeek,
      calendarSettings,
      accommodation,
    },
    setStoreData,
  ] = useContext(SchedularContext);

  if (
    loading ||
    !allUsers ||
    !(allShifts && allShifts[activeYear] && allShifts[activeYear][activeWeek])
  ) {
    return <></>;
  }

  const { client, flash, i18n } = useContext(ServicesContext);

  const dp = useRef();
  const calendarRef = dp?.current?.control;

  const { start: startDate, end: endDate } = activeCalendarDates;

  const dateRangeCode = makeKeyYearDates(startDate, endDate);
  const employees = allUsers[dateRangeCode];

  let visibleShifts = makeVisibleShifts(
    filters,
    accommodation,
    allShifts[activeYear][activeWeek]
  );

  const shiftEvents = makeShiftEvents(visibleShifts);

  const [showErrorModal, setShowErrorModal] = useState(false);
  const [activeResponse, setActiveResponse] = useState({});

  const onEventDrop = async (ev, args) => {
    ev.preventDefault();
    if (!args.e.data.items) {
      args.e.data.items = [];
    }
    const data = JSON.parse(ev.dataTransfer.getData('daypilot/external-item'));
    const empId = data.id;

    updateShiftWithEmployee(
      { id: empId, name: data.name },
      args.e.data.id,
      setStoreData,
      activeYear,
      activeWeek
    );

    const response = await addUserInShift(empId, args.e.data.id, client);
    const resData = response?.data;

    if (response.ok == false) {
      removeEmployeeFromStore(
        setStoreData,
        activeYear,
        activeWeek,
        empId,
        args.e.data.id
      );
      flash.error('Mitarbeiter ist für diese Schicht nicht verfügbar');
    }

    if (
      resData?.isAvailable === false ||
      resData?.isUserOverlappingShift === true ||
      resData?.managerRequired == true
    ) {
      removeEmployeeFromStore(
        setStoreData,
        activeYear,
        activeWeek,
        empId,
        args.e.data.id
      );
      if (resData?.managerRequired == true) {
        flash.error(i18n.tr('shift.shift.managerRequired'));
      } else {
        flash.error('Mitarbeiter ist für diese Schicht nicht verfügbar');
      }
      return;
    }
    if (
      resData?.isOverTime > 0 ||
      resData?.restingPeriodViolation == true ||
      resData?.isFreeSundayAvailable == false ||
      resData?.youthWorkingHoursViolation == true ||
      resData?.isFullTime == false
    ) {
      removeEmployeeFromStore(
        setStoreData,
        activeYear,
        activeWeek,
        empId,
        args.e.data.id
      );

      setShowErrorModal(true);
      setActiveResponse({
        ...resData,
        user: empId,
        username: data.name,
        shift: args.e.data.id,
      });
    }
  };

  const onAfterEventRender = (args) => {
    args.div.addEventListener('dragover', (ev) => {
      const hasMyType = ev.dataTransfer.types.some(function (type) {
        return type === 'daypilot/external-item';
      });
      if (hasMyType) {
        ev.preventDefault();
        ev.dataTransfer.dropEffect = 'move';
      }
      args.div.classList.add('dragging-over');
    });

    args.div.addEventListener('dragleave', (ev) => {
      args.div.classList.remove('dragging-over');
    });

    args.div.addEventListener('drop', (ev) => onEventDrop(ev, args));
  };

  const onBeforeEventDomAdd = (args) => {
    args.e.data.moveDisabled = true;
    const data = args.e.data;
    const items = data?.items;
    args.element = (
      <div>
        <a
          href={`/view/shift/shift-detail?bulk=false&id=${data?.id}&modelId=shift%2Fshift`}
          target="blank"
          className="shift-title"
        >
          {data?.text}
        </a>
        {items?.length > 0 &&
          items.map((item) => {
            const text = DayPilot.Util.escapeHtml(
              item.name ? item.name : getEmployeeName(item.id, employees)
            );
            return (
              <div
                key={item.id}
                onDoubleClick={() =>
                  removeEmployeeFromShift(
                    item.id,
                    data.id,
                    setStoreData,
                    activeYear,
                    activeWeek,
                    client,
                    allShifts
                  )
                }
                className="employee-item"
                draggable={true}
              >
                <i
                  onClick={() =>
                    removeEmployeeFromShift(
                      item.id,
                      data.id,
                      setStoreData,
                      activeYear,
                      activeWeek,
                      client,
                      allShifts
                    )
                  }
                  className="fa fa-close"
                ></i>
                {text}
              </div>
            );
          })}
      </div>
    );
    return args;
  };

  const convertTime = (timeStr) => {
    if (!timeStr) {
      return undefined;
    }
    let hourStr = timeStr.substring(0, 2);
    let hour = parseInt(hourStr, 10);
    return hour;
  };

  return (
    <div>
      <CalendarTopRow />
      <div className="schedule-calendar-parent">
        <DayPilotCalendar
          ref={dp}
          startDate={new Date(startDate)}
          days={duration}
          viewType={'week'}
          eventHeight={30}
          snapToGrid={false}
          events={shiftEvents}
          timeRangeSelectedHandling="Disabled"
          cellWidthSpec="Full"
          headerHeight="50"
          dayBeginsHour={
            convertTime(calendarSettings?.calendarStartTime) || dayBeginsHour
          }
          businessBeginsHour={businessBeginsHour}
          businessEndsHour={businessEndsHour}
          allowEventOverlap={true}
          locale={'de-DE'}
          eventHoverHandling={'Bubble'}
          headerDateFormat={'d.MM.yyyy<br />dddd'}
          onAfterEventRender={onAfterEventRender}
          heightSpec={'Parent100Pct'}
          onBeforeEventDomAdd={onBeforeEventDomAdd}
        />
      </div>
      {showErrorModal && (
        <ValidationModal
          showModal={showErrorModal}
          setShowModal={setShowErrorModal}
          activeResponse={activeResponse}
          setActiveResponse={setActiveResponse}
          activeYear={activeYear}
          activeWeek={activeWeek}
          setStoreData={setStoreData}
        />
      )}
    </div>
  );
};

export default ShiftCalendar;
